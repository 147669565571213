@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
  font-family: "Figtree", sans-serif;
}
.container {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  height: 650px;
}

/* dropdown */
.dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
}

/* .dropdown {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  cursor: pointer;
  width: calc(100% - 20px);
  position: relative;
}

.dropdown::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
}

.dropdown:hover {
  background-color: #f8f9fa;
}

.dropdown:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
} */


.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  margin-left: 10px;
}
.centered-container > * {
  margin-bottom: 10px;
}

.button-container {
  margin-top: 5px;
  margin-right: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.heading-container{
  text-align: center;
  margin-top: 10px;
}

.board{
  margin-left: 20px;
}

/* Modal */
.close-btn{
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  max-width: 30px;
}

.table-container {
  margin: 0 auto;
  padding: 10px;
  max-width: 100%;
}

.data-table {
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
  border-collapse: collapse;
}

.data-table td {
  padding: 10px;
  /* border: 1px solid #ddd; */
}

.select {
  width: 100%;
  max-width: 300px;
}

.submit-btn {
  text-align: center;
  margin-top: 20px;
}

.mapping-heading {
  text-align: center;
  margin-top: 10px;
  color: #696868cc;
}
